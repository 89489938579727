.staffing_mart_sec{
    background: #e6d6d6;
    padding: 50px 0;
    height: calc(100vh - 52px);
    width: 100%;
    /* display: grid;
    place-items: center; */
    overflow: hidden;
    overflow-y: auto;
}

.staffing_mart_sec .container{
    max-width: 1230px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    height: 100%;
}

.staffing_row_wrap{
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    padding: 0;
    display: flex;
    align-items: stretch;
    gap: 20px;
    height: 100%;
}

.left-section {
    flex: 1 1 auto;
    width: calc(100% - 470px);
}
.right-section {
    max-width: 450px;
    width: 100%;
    flex: 0 0 auto;
    position: relative;
}

.content {
    padding: 1rem;
    /* max-height: 550px; */
    max-height: calc(100% - 55px);
    overflow-y: auto; /* Enable vertical scrolling */
}
.content h2{
    font-size: 22px;
    line-height: 28px;
}

/* width */
.content::-webkit-scrollbar, 
.staffing_mart_sec::-webkit-scrollbar, .dropdown-menu::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.content::-webkit-scrollbar-track, 
.dropdown-menu::-webkit-scrollbar-track, 
.staffing_mart_sec::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.content::-webkit-scrollbar-thumb, 
.dropdown-menu::-webkit-scrollbar-thumb, 
.staffing_mart_sec::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.content::-webkit-scrollbar-thumb:hover, 
.dropdown-menu::-webkit-scrollbar-thumb:hover, 
.staffing_mart_sec::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.staffing_mart_logo{
    max-width: 250px;
    width: 100%;
    margin: 0 0 20px;
}

  .staffing_mart_logo .logo {
    width: 100%;
    display: block;
  }
  
  .title {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 20px;
  }
  .title::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 3px;
    background: #fbb03c;
    bottom: 0;
    left: 0;
  }
  
  .description {
    font-size: 18px;
    line-height: 24px;
  }
  .sm_content_wrapper p{
    margin: 0 0 10px;
  }

  
  .social-media {
      margin-top: 20px;
    }
    
    .sm_contact_us{
      margin: 20px 0 0;
    }

  .social-title {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 15px;
  }
  
  .social-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .social-icon {
    font-size: 1.5em;
    text-decoration: none;
    color: #36454f;
    background-color: #fbb03c;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .menu {
    font-size: 1.5em;
    color: #36454f;
  }

  .button {
    background: #fbb03c;
    color: #36454f;
    /* padding: 0.5rem 1rem; */
    padding: 8px 10px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    /* margin-top: 1rem; */
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .mobile-hint {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .mobile-hint img {
    width: 50px;
    margin-right: 1rem;
  }
  
  .mobile-hint span {
    font-size: 0.9rem;
  }
  

  
  .phone-frame {
    width: 100%;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    /* padding: 0 0 15px; */
    /* position: relative; */
  }

    .dropdown-menu {
        padding: 10px;
        background: #ffffff;
        position: absolute;
        display: block;
        width: 100%;
        left: 0;
        right: 0;
        border-top: 1px solid #dddddd;
        top: 54px;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        max-height: 150px;
    }
    .dropdown-menu .tab{
        padding: 5px 0;
        font-size: 16px;
        line-height: 20px;
    }

    .phone-header>.tab{
        font-size: 18px;
        line-height: 22px;
    }
  
  .phone-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 1rem;
    background: #f8f8f8;
    border-bottom: 1px solid #dddddd;
  }
  
  .tab {
    padding: 0;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tab.active {
    font-weight: bold;
    margin: 0;
    color: #36454f;
  }


.categories-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.category-item {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image_wrapper{
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
}
.category-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.content .category-title {
  font-size: 20px;
  line-height: 25px;
  margin: 1rem 0;
  padding: 0;
}

.category-description {
  font-size: 18px;
  line-height: 24px;
  color: #333;
}

.fab_tab_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

button.tab_btn {
  flex: 1 1 auto;
  border: 1px solid #36454f;
  background-color: #36454f;
  color: #ffffff;
  padding: 6px 5px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
button.tab_btn.active{
  color: #36454f;
  background-color: #ffffff;
}

.sm_faq_wrapper h2, .why_choose_us_wrap h2{
    margin-top: 0;
}
.sm_faq_wrapper h2, .why_choose_us_wrap ul li:not(:last-child){
    padding-bottom: 7px;
}

.sm_faq_wrapper .faq_queans_wrap{
    margin: 0 0 15px;
    background-color: #f8f8f8;
    padding: 8px 10px;
    border-radius: 10px;
    border: 1px solid #dddddd;
    cursor: pointer;
}
.sm_faq_wrapper .faq_queans_wrap:last-child{
    margin: 0;
}

.sm_faq_wrapper .faq_queans_wrap button{
    padding: 5px 0;
    border: none;
    background-color: transparent;
    /* padding: 0; */
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    text-align: left;
}
.sm_faq_wrapper .faq_queans_wrap p{
    padding: 5px 0 0;
}

  .sm_footer {
    background: #333;
    text-align: center;
    padding: 15px 10px;
  }
  .sm_footer p{
    color: #ffffff;
    line-height: 22px;
  }
  
  .primary-text {
    color: #36454f;
  }

  .tab-title {
    margin: 5px;
  }

  .services-item{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 160px;
    border: 5rem solid #36454f;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  @media (max-width: 1024px) {
    .right-section{
        max-width: 350px;
    }
    .left-section {
        width: calc(100% - 350px);
    }
  }
  @media (max-width: 991px) {
    .title {
        font-size: 22px;
        line-height: 28px;
    }
    .content .category-title {
        font-size: 20px;
        line-height: 24px;
        margin: 10px 0;
    }
    /* .category-description {
        font-size: 16px;
        line-height: 20px;
    } */
  }

  @media (max-width: 768px) {
    .staffing_row_wrap{
        flex-direction: column;
    }
    .left-section {
        width: 100%;
    }
    .right-section {
        max-width: 100%;
    }
    .sm_detail_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 20px 0 0;
    }
    .social-media, .sm_contact_us{
        margin: 0;
    }
    .sm_contact_us .button {
        font-size: 15px;
    }
    .social-icon{
        font-size: 15px;
        width: 30px;
        height: 30px;
    }
    .svg-inline--fa {
        height: 18px;
    }
    .staffing_mart_logo {
        margin: 0 auto 20px;
    }
    .staffing_mart_sec {
        padding: 40px 0;
    }
    .staffing_row_wrap, .staffing_mart_sec .container, .phone-frame{
        height: auto;
    }
    .content {
        max-height: 550px;
    }
    .sm_faq_wrapper h2, .why_choose_us_wrap ul{
      padding: 0 0 0 30px;
    }
  }

  @media (max-width: 480px) {
    .categories-section {
        grid-template-columns: repeat(1, 1fr);
    }
    .sm_footer p{
      font-size: 16px;
      line-height: 20px;
    }
    .staffing_mart_sec {
      height: calc(100vh - 50px);
    }
    
  }