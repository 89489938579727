/* fonts TT Commons */
@font-face { font-family: 'TT Commons'; src: url('../fonts/TTCommons-Regular.woff2') format('woff2'), url('../fonts/TTCommons-Regular.woff') format('woff'); font-weight: 400; font-style: normal; font-display: swap; }
@font-face { font-family: 'TT Commons'; src: url('../fonts/TTCommons-Medium.woff2') format('woff2'), url('../fonts/TTCommons-Medium.woff') format('woff'); font-weight: 500; font-style: normal; font-display: swap; }
@font-face { font-family: 'TT Commons'; src: url('../fonts/TTCommons-DemiBold.woff2') format('woff2'), url('../fonts/TTCommons-DemiBold.woff') format('woff'); font-weight: 600; font-style: normal; font-display: swap; }
@font-face { font-family: 'TT Commons'; src: url('../fonts/TTCommons-Bold.woff2') format('woff2'), url('../fonts/TTCommons-Bold.woff') format('woff'); font-weight: 700; font-style: normal; font-display: swap; }
@font-face { font-family: 'TT Commons'; src: url('../fonts/TTCommons-ExtraBold.woff2') format('woff2'), url('../fonts/TTCommons-ExtraBold.woff') format('woff'); font-weight: 800; font-style: normal; font-display: swap; }
@font-face { font-family: 'TT Commons'; src: url('../fonts/TTCommons-Black.woff2') format('woff2'), url('../fonts/TTCommons-Black.woff') format('woff'); font-weight: 900; font-style: normal; font-display: swap; }





html { overflow-x: hidden;}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



/*Default-CSS*/
input[type="file"]::-webkit-file-upload-button { cursor: pointer; } input[type="file"]::-moz-file-upload-button { cursor: pointer; } input[type="file"]::-ms-file-upload-button { cursor: pointer; } input[type="file"]::-o-file-upload-button { cursor: pointer; }
button { cursor: pointer; font-family: 'TT Commons', sans-serif; }
input[type="file"] { cursor: pointer; }
html body { font-family: 'TT Commons', sans-serif; margin: 0; padding-top: 0px; overflow-x: hidden; counter-reset: my-sec-counter; }
*::-moz-selection { color: #fff; background: #000; } *::-webkit-selection { color: #fff; background: #000; } 
*::-webkit-input-placeholder { color: #cccccc; opacity: 1; } *:-moz-placeholder { color: #cccccc; opacity: 1; } *::-moz-placeholder { color: #cccccc; opacity: 1; } *:-ms-input-placeholder { color: #cccccc; opacity: 1; }
a, div a:hover, div a:active, div a:focus, button { text-decoration: none; -webkit-transition: all 0.5s ease 0s; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; }
a, span, div a:hover, div a:active, button { text-decoration: none; }
*::after, *::before, * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; }
body a { outline: none; color: #555; }
body .clearfix, body .clear { clear: both; line-height: 100%; }
body .clearfix { height: auto; }
* { outline: none !important; }
.clr:after, ul:after, .clearfix:after, li:after{ clear: both; display: block; content: ""; }
div input, div select, div textarea, div button { font-weight: 400; }
body h1, body h4, body h5, body h6 { line-height: 120%; margin: 0 0 15px; font-weight: 700; }
body h1 { font-size: 24px; } body h4 { font-size: 16px; } body h5 { font-size: 12px; } body h6 { font-size: 10px; }
body h1:last-child, body h2:last-child, body h3:last-child, body h4:last-child, body h5:last-child, body h6:last-child { margin-bottom: 0; }
div select { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
div select option { font-size: 13px; color: #393939; padding: 2px 5px; }
img { margin: 0 auto; max-width: 100%; max-height: 100%; width: auto; height: auto; }
body p { color: #333; font-size: 18px; line-height: 24px; margin: 0 0 15px; padding: 0; }
body p:empty { margin: 0; line-height: 0; }
body p:last-child { margin-bottom: 0; }
p strong { font-weight: bold; }
.hidden { display: none !important; }
iframe { display: block; width: 100%; }
.container:after { content: ""; display: block; clear: both; }
.main_btn { background: linear-gradient(92.09deg, #CF48FF 4.66%, #6850FF 98.59%); border-radius: 10px; height: 80px; display: inline-block; vertical-align: top; line-height: 80px; font-size: 28px; letter-spacing: 0.05em; color: #FFFFFF; font-family: 'TT Commons';font-weight: 700; padding: 0 44px; min-width: 283px; text-align: center; position: relative; overflow: hidden; }
.main_btn::before { content: ""; position: absolute; left: 3px; top: 3px; right: 3px; bottom: 3px; transition: all 0.3s ease-in-out; border-radius: 10px; background: rgb(12 9 45 / 40%); transform: scale(0); }
/* .main_btn:hover { opacity: 0.8; } */
.main_btn:hover::before { transform: scale(1); }
.main_btn > span{display: block;position: relative;}
/*scroll Hide*/
html.no-scroll { overflow: hidden; }
html.no-scroll body { overflow: hidden; height: 100%; }